import { mapActions, mapGetters, mapState } from 'vuex'
import SSelect from '@/components/ui/s-select'
import Metrics from '@/plugins/metrics'

export default {
  name: 'StepThree',

  props: {
    report: {
      type: Object,
      default: () => ({}),
    },
    isAnonymous: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    AccusedField: () => import('@/components/ReportFields/Accused'),
    AreasField: () => import('@/components/ReportFields/Areas'),
    SSelect,
  },

  data: () => ({
    areas: [],
    hasCustomArea: false,
    involved: null,
    maxLength: 25,
  }),

  async created() {
    if (this.report.customInvolved[0]) {
      this.customInvolved = []
      Object.values(this.report.customInvolved).map((name) =>
        this.customInvolved.push({ name }),
      )
    }

    this.areas = await this.getAreas()
  },

  mounted() {
    const { source, channel, anonymous } = this.report
    const payloadMetrics = {
      source,
      channel,
      anonymous,
    }

    Metrics.track('Report Step Three Started', payloadMetrics)
  },

  methods: {
    ...mapActions(['getAreas']),

    clearInvolved() {
      this.report.customInvolved = []
      this.report.involved = []
      this.report.areas = []
    },

    addField() {
      this.report.customInvolved.push(this.involved)
      this.involved = null
    },

    addCustomAreaField() {
      this.hasCustomArea = true
    },

    addInvolved() {
      if (!this.involved) {
        return
      }

      this.addCustomInvolved(this.involved)

      this.involved = null
    },

    addCustomArea(customArea) {
      this.report.customArea = [...this.report.customArea, customArea]
    },

    removeInvolved(item) {
      this.report.customInvolved = this.report.customInvolved.filter(
        (involved) => involved !== item,
      )
    },

    addCustomInvolved(customInvolved) {
      this.report.customInvolved = [
        ...this.report.customInvolved,
        customInvolved,
      ]
    },

    resetCustomArea(value) {
      this.report.customArea = !value ? '' : this.report.customArea
    },
  },

  computed: {
    ...mapState(['company', 'locale']),
    ...mapGetters([
      'enabledRelations',
      'isFeatureEnabled',
      'isSourceCollaborator',
    ]),

    key() {
      return this.locale?.substring(0, 2)
    },

    isAreaMandatory() {
      const isAreaMandatory = this.isFeatureEnabled('areaMandatory')
      return isAreaMandatory || this.accusedIsCollaborator
    },

    accusedIsCollaborator() {
      return this.isSourceCollaborator(this.report.accusedSource)
    },

    validLocale() {
      return this.locale === 'pt-BR' ? 'pt' : this.locale
    },

    relationOptions() {
      return this.enabledRelations.map((item) => ({
        value: item.key,
        label: item[this.validLocale],
      }))
    },
  },
}
